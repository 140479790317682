import React from 'react'
import { Text } from '@chakra-ui/core'

const TermosText = () => {
	return (
		<>
			<Text fontSize='lg' color='gray.900' textAlign='center' mb={4}>
				Termo de consentimento para tratamento de dados pessoais
			</Text>
			<Text textAlign='left'>
				Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o
				tratamento de seus dados pessoais, sensíveis ou não, para finalidade específica de tramitação de Receita Médica
				Digital, através de aplicativos web e dispositivos móveis, no endereço eletrônico www.receitamedica.com.vc e
				todos os subdomínio, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
				<br />
				<br />
				Ao dar seu ACEITE no presente termo, o Titular consente e concorda que a empresa VALID SOLUÇÕES S.A, bem como
				suas coligadas ou filiadas, inscrita no CNPJ/MF sob nº 33.113.309/0001-47, com sede na Cidade e Estado do Rio de
				Janeiro, na Rua Peter Lund, 146/202, São Cristóvão, CEP 20.930-901, e-mail communication@valid.com, doravante
				denominada Controladora, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o
				tratamento de seus dados pessoais, sensíveis ou não, envolvendo operações como as que se referem a coleta,
				produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
				arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
				transferência, difusão ou extração.
				<br />
				<br />
				Dados Pessoais
				<br />
				O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes
				dados pessoais do Titular: Nome completo. Data de nascimento. Número e imagem da Carteira de Identidade (RG).
				Número e imagem do Cadastro de Pessoas Físicas (CPF). Número e imagem da Carteira Nacional de Habilitação (CNH).
				Número e imagem da Carteira Profissional Registrada em Órgão de Classe. Número e imagem da Carteira de Seguro e
				Convênio Médico. Fotografia 3x4. Estado civil. Nível de instrução ou escolaridade. Endereço completo. Números de
				telefone, WhatsApp e endereços de e-mail. Nome de usuário e senha específicos para uso dos serviços do
				Controlador. Comunicação, verbal e escrita, mantida entre o Titular e o Controlador. Referente à saúde ou à vida
				sexual, dado genético ou biométrico.
				<br />
				<br />
				Finalidades do Tratamento dos Dados
				<br />
				O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades: Possibilitar o exercício
				médico profissional ao Titular através da Telemedicina. Possibilitar a prescrição e dispensação de medicamentos
				ao Titular em todo território nacional. Possibilitar que o Controlador receba e processe as informações e dados
				para autenticação e assinatura da receita médica ao Titular. Possibilitar que o Controlador envie ou forneça ao
				Titular seus produtos e serviços, de forma remunerada ou gratuita. Possibilitar que o Controlador estruture,
				teste, promova e faça propaganda de produtos e serviços, personalizados ou não ao perfil do Titular.
				<br />
				<br />
				Compartilhamento de Dados
				<br />
				O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de
				dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias
				estabelecidas pela Lei nº 13.709.
				<br />
				<br />
				Término do Tratamento dos Dados
				<br />
				O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período em que forem
				pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de
				associação ao indivíduo, poderão ser mantidos por período indefinido.
				<br />
				<br />
				O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam
				eliminados os dados pessoais não anonimizados do Titular. O Titular fica ciente de que poderá ser inviável ao
				Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados
				pessoais.
				<br />
				<br />
				Direitos do Titular
				<br />
				O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento e
				mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados
				incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários,
				excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro
				fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial,
				de acordo com a regulamentação do órgão controlador; V - portabilidade dos dados a outro fornecedor de serviço
				ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os
				segredos comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular,
				exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas
				com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a possibilidade de não
				fornecer consentimento e sobre as consequências da negativa; IX - revogação do consentimento, nos termos do § 5º
				do art. 8º da Lei nº 13.709.
				<br />
				<br />
				Direito de Revogação do Consentimento
				<br />
				Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou
				correspondência ao Controlador.
				<br />
				<br />
				Atualizações Última atualização em 04/05/2020.
				<br />
				<br />
			</Text>
		</>
	)
}

export default TermosText
