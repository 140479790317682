import React from 'react'
import Layout from '../components/layout'
import Header from '../components/ExternalPagesHeader'
import TermosText from '../components/TermosText'
import SEO from '../components/seo'
import { Box } from '@chakra-ui/core'

const TermosPage = () => {
	return (
		<Layout>
			<Header />
			<SEO title='Termo de consentimento para tratamento de dados pessoais' />

			<Box p={8} color='gray.700' lineHeight='1.75rem'>
				<TermosText />
			</Box>
		</Layout>
	)
}

export default TermosPage
